import _slicedToArray from "/tmp/build_f326546b/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectSpread from "/tmp/build_f326546b/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { GET_USER } from '../../../store/queries/usersQueries';
import { createImpersonateClient } from '../../../store';
import { withContext } from '../../../context';
import { impersonateSuccessAction } from '../../../store/actions/authActions';
var Impersonate = function Impersonate(_ref) {
  var impersonateSuccess = _ref.impersonateSuccess,
    impersonate = _ref.impersonate,
    toastManager = _ref.toastManager;
  var impersonateCredentials = useMemo(function () {
    var urlParams = new URLSearchParams(window.location.search);
    var userId = urlParams.get('id');
    var accountId = urlParams.get('accountId');
    var token = urlParams.get('impersonate');
    if (token && userId && accountId) {
      return {
        token: token,
        userId: userId,
        accountId: accountId
      };
    }
    return null;
  }, []);
  useEffect(function () {
    if (impersonate.active) {
      toastManager.add('You already use impersonate. Quit the previous one to use the new one', {
        appearance: 'error'
      });
      return;
    }
    if (impersonateCredentials) {
      var token = impersonateCredentials.token,
        userId = impersonateCredentials.userId,
        accountId = impersonateCredentials.accountId;
      createImpersonateClient(token, accountId).query({
        query: GET_USER,
        variables: {
          id: userId
        },
        fetchPolicy: 'network-only'
      }).then(function (_ref2) {
        var byId = _ref2.data.byId;
        return impersonateSuccess(_objectSpread({}, byId, {
          token: token
        }));
      });
    }
  }, [impersonateCredentials]);
  return null;
};
Impersonate.propTypes = {
  impersonateSuccess: PropTypes.func.isRequired,
  impersonate: PropTypes.object.isRequired,
  toastManager: PropTypes.object.isRequired
};
export default withToastManager(withContext(function (_ref3) {
  var _ref4 = _slicedToArray(_ref3, 2),
    user = _ref4[0].user,
    dispatch = _ref4[1];
  return {
    impersonate: user.impersonate,
    // actions
    impersonateSuccess: function impersonateSuccess(data) {
      return impersonateSuccessAction(data, dispatch);
    }
  };
}, Impersonate));